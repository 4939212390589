import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/layout/Layout"
import { 
    SectionWrapper, 
    SectionWrapperGray, 
    InnerWrapper, 
    SectionSubHeader 
} from "../components/Wrapper"

const AboutPage = ({ data }) => {
    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Page Not Found`}
                description={`All Ins Agency`}
            />
            <SectionWrapperGray className="mt-8">
                <InnerWrapper className="my-12">
                    <SectionSubHeader>404 Page Not Found</SectionSubHeader>
                </InnerWrapper>
            </SectionWrapperGray>
            <SectionWrapper>
                <InnerWrapper className="mt-12 mb-20">
                    <p>Sorry, the page you are looking for does not exist or is temporary unavailable.</p>
                    <p>Please return to our <Link to="/">home page</Link>.</p>
                </InnerWrapper>
            </SectionWrapper>
        </Layout >
    )
}

export default AboutPage
